import { createClient } from '@supabase/supabase-js';
import bootstrap from 'bootstrap'

// Initialize Supabase client with your project’s URL and anon key
const supabaseUrl = 'https://backvzfjffrzhsclplro.supabase.co'; // replace with your actual URL
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJhY2t2emZqZmZyemhzY2xwbHJvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUzMDYxMTksImV4cCI6MjA0MDg4MjExOX0.bQ8myr-VBiI8paYJC-nvBDnU6XQg6bTFVwaeKkfFtg4'; // replace with your actual anon key
const supabase = createClient(supabaseUrl, supabaseKey);

// Define the screens
const screens = {
  login: document.getElementById('login-screen'),
  dashboard: document.getElementById('dashboard-screen'),
  study: document.getElementById('study-screen'),
  flashcardForm: document.getElementById('flashcard-form-screen'),
};

// Define buttons and elements
const buttons = {
  logout: document.getElementById('logout-button'),
  startStudy: document.getElementById('start-study-button'),
  flipCard: document.getElementById('flip-card-button'),
  nextCard: document.getElementById('next-card-button'),
  prevCard: document.getElementById('prev-card-button'),
  backToDashboard: document.getElementById('back-to-dashboard-button'),
  correct: document.getElementById('correct-button'),
  incorrect: document.getElementById('incorrect-button'),
};

const flashcardText = document.getElementById('flashcard-text');
let flashcards = [];  // Array to hold fetched flashcards
let currentCardIndex = 0; // Track current flashcard
let flipped = false; // Track flip state
let correctCount = 0;
let incorrectCount = 0;

// Screen navigation logic
function showScreen(screenToShow) {
  Object.values(screens).forEach(screen => screen.style.display = 'none');
  screenToShow.style.display = 'block';
}

// Check if the user is already logged in
async function checkAuth() {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error || !session) {
      console.log('No user session found.');
      showScreen(screens.login);
    } else {
      console.log('User is logged in:', session.user);
      showScreen(screens.dashboard);
    }
  } catch (err) {
    console.error('Error checking session:', err);
  }
}

// Log in a user
async function login(email, password) {
  try {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;
    console.log('Login successful:', data);
    showScreen(screens.dashboard);
    fetchFlashcards(); // Fetch flashcards after login
  } catch (err) {
    console.error('Login error:', err.message);
  }
}

// Handle form submission for login
document.getElementById('login-form').addEventListener('submit', async (e) => {
  e.preventDefault();
  const email = document.getElementById('login-email').value;
  const password = document.getElementById('login-password').value;
  await login(email, password);
});

// Logout the user
async function logout() {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    console.log('User logged out');
    showScreen(screens.login);
  } catch (err) {
    console.error('Logout error:', err.message);
  }
}

// Fetch flashcards for the current user
async function fetchFlashcards() {
  try {
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError) throw sessionError;

    const { data: flashcardsData, error } = await supabase
      .from('flashcards')
      .select('*')
      .eq('user_id', session.user.id);

    if (error) throw error;

    flashcards = flashcardsData;
    console.log('Fetched flashcards:', flashcards);  // Log the fetched flashcards to see if it's populated

    if (flashcards.length === 0) {
      flashcardText.textContent = 'No flashcards available.';
    } else {
      displayFlashcard(); // Show the first flashcard
    }
  } catch (err) {
    console.error('Error fetching flashcards:', err.message);
  }
}

// Create a new flashcard
async function createFlashcard(front, back) {
  try {
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError) throw sessionError;

    const { data, error } = await supabase
      .from('flashcards')
      .insert([{ front, back, user_id: session.user.id }]);

    if (error) throw error;
    console.log('Flashcard created:', data);
  } catch (err) {
    console.error('Error creating flashcard:', err.message);
  }
}

// Handle flashcard creation form
document.getElementById('flashcard-form').addEventListener('submit', async (e) => {
  e.preventDefault();
  const front = document.getElementById('front').value;
  const back = document.getElementById('back').value;
  await createFlashcard(front, back);

  // Clear the form
  document.getElementById('front').value = '';
  document.getElementById('back').value = '';
});

// Display current flashcard
function displayFlashcard() {
  const flashcardText = document.getElementById('flashcard-text');

  if (!flashcards || flashcards.length === 0) {
    flashcardText.textContent = 'No flashcards available.';
    return;
  }

  // Ensure the current index is valid
  if (!flashcards[currentCardIndex]) {
    flashcardText.textContent = 'Error: Flashcard not found.';
    return;
  }

  // Show the front or back of the card
  flashcardText.textContent = flipped ? flashcards[currentCardIndex].back : flashcards[currentCardIndex].front;
}

// Handle flip card functionality
buttons.flipCard.addEventListener('click', () => {
  flipped = !flipped;
  displayFlashcard();
});

// Handle next card functionality
buttons.nextCard.addEventListener('click', () => {
  currentCardIndex = (currentCardIndex + 1) % flashcards.length;
  flipped = false;
  displayFlashcard();
});

// Handle previous card functionality
buttons.prevCard.addEventListener('click', () => {
  currentCardIndex = (currentCardIndex - 1 + flashcards.length) % flashcards.length;
  flipped = false;
  displayFlashcard();
});

// Handle correct and incorrect tracking
buttons.correct.addEventListener('click', () => {
  correctCount++;
  nextCard();
  console.log(`Correct: ${correctCount}, Incorrect: ${incorrectCount}`);
});

buttons.incorrect.addEventListener('click', () => {
  incorrectCount++;
  nextCard();
  console.log(`Correct: ${correctCount}, Incorrect: ${incorrectCount}`);
});

// Move to the next flashcard
function nextCard() {
  currentCardIndex = (currentCardIndex + 1) % flashcards.length;
  flipped = false;
  displayFlashcard();
}

// Handle the back-to-dashboard button from study mode
buttons.backToDashboard.addEventListener('click', () => {
  showScreen(screens.dashboard);
});

// Start study mode
buttons.startStudy.addEventListener('click', () => {
  currentCardIndex = 0;
  flipped = false;
  displayFlashcard();
  showScreen(screens.study);
});

// Call checkAuth when the app loads
window.addEventListener('DOMContentLoaded', checkAuth);
buttons.logout.addEventListener('click', logout);
